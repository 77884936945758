<template>
  <div>
    <el-form ref="queryForm" :inline="true" :model="queryForm" size="small">
      <el-form-item label="标题" prop="key">
        <el-input v-model="queryForm.key" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select v-model="queryForm.status" placeholder="请选择">
          <el-option label="启用" :value="1"></el-option>
          <el-option label="禁用" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleReset" type="infor">重置</el-button>
        <el-button @click="handleAdd" type="primary">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" v-loading="tableLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" style="width: 100%;">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column prop="title" label="标题"></el-table-column>
      <el-table-column label="内容" v-slot="{ row }">
        <el-popover placement="top-start" width="200" trigger="hover" :content="row.content">
          <span slot="reference" class="ellipsis">{{ row.content }}</span>
        </el-popover>
      </el-table-column>
      <el-table-column label="banner" v-slot="{ row }">
        <img :src="row.banner" alt="加载失败" style="width: 100px;">
      </el-table-column>
      <el-table-column label="附件" v-slot="{ row }">
        <a v-if="row.attachment" :href="row.attachment" target="_blank">附件</a>
      </el-table-column>
      <el-table-column label="状态">
        <template v-slot="{ row }">
          <el-tag v-if="row.status === 1" type="success" size="small">启用</el-tag>
          <el-tag v-else type="info" size="small">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-link @click="handleEdit(row)" type="primary">编辑</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" :page-size="pageSize" @current-change="getNoticeData">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑公告的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" @close="closeDialog"
      width="500px" top="8vh">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input v-model="form.content" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="banner" prop="banner">
          <el-upload :action="UPLOAD_URL" :limit="1" :file-list="bannerFileList"
            :before-upload="handleBannerBeforUpload" :on-success="handleBannerSuccess" :on-remove="handleBannerRemove"
            :on-exceed="handleFileExceed" list-type="picture-card">
            <i class="el-icon-plus"></i>
            <div slot="tip">只能上传jpg/png文件，数量限制：1</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="附件" prop="attachment">
          <el-upload :action="UPLOAD_URL" :limit="1" :file-list="attachmentFileList"
            :before-upload="handleAttachmentBeforUpload" :on-success="handleAttachmentSuccess"
            :on-remove="handleAttachmentRemove" :on-exceed="handleFileExceed">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip">只能上传jpg/png/pdf文件，数量限制：1 </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    UPLOAD_URL,
  } from '@/utils/constant.js'

  import {
    saveNoticeAPI,
    getNoticeDataAPI,
  } from '@/api/notice.js'

  export default {
    name: 'Notice',
    data() {
      return {
        UPLOAD_URL,

        queryForm: {
          page: 1,
          key: '',
          status: ''
        },

        // 表格相关
        tableData: [],
        total: 0,
        pageSize: 0,
        curRowData: null,
        tableLoading: false,


        // 上传文件相关
        bannerFileList: [],
        attachmentFileList: [],

        // 表单相关
        showDialog: false,
        dialogTitle: '',
        submitting: false,
        form: {
          title: '',
          content: '',
          banner: '',
          status: 1,
          attachment: ''
        },
        rules: {
          title: [{
            required: true,
            message: '标题不能为空',
            trigger: 'blur'
          }],
          content: [{
            required: true,
            message: '内容不能为空',
            trigger: 'blur'
          }],
          banner: [{
            required: true,
            message: 'banner不能为空',
          }]
        }
      }
    },
    created() {
      this.getNoticeData()
    },
    methods: {
      // 新增按钮
      handleAdd() {
        this.dialogTitle = '新增'
        this.showDialog = true
      },
      // 查询按钮
      handleSearch() {
        this.queryForm.page = 1
        this.getNoticeData()
      },
      // 重置按钮
      handleReset() {
        this.$refs.queryForm.resetFields()
      },
      closeDialog() {
        this.bannerFileList = []
        this.attachmentFileList = []
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      // 编辑按钮
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowData = JSON.parse(JSON.stringify(row))
        // 回显 banner 和 attachment
        if (this.curRowData.banner) {
          this.bannerFileList = [{
            url: this.curRowData.banner
          }]
        }
        if (this.curRowData.attachment) {
          this.attachmentFileList = [{
            url: this.curRowData.attachment,
            name: '附件',
          }]
        }
        // 设置 form
        for (let key in this.form) {
          this.form[key] = this.curRowData[key]
        }
      },
      // 文件超出个数限制时的钩子
      handleFileExceed() {
        this.$message.warning('上传失败，文件数量超出限制')
      },
      // banner上传之前
      handleBannerBeforUpload(file) {
        if (file.type.indexOf('png') < 0 && file.type.indexOf('jpeg') < 0) {
          this.$message.error('文件格式错误')
          return false
        }
      },
      // banner上传成功
      handleBannerSuccess(res, file, fileList) {
        this.bannerFileList = fileList
      },
      // banner删除
      handleBannerRemove(file, fileList) {
        this.bannerFileList = fileList
        this.form.banner = ''
      },
      // 附件上传之前
      handleAttachmentBeforUpload(file) {
        if (
          file.type.indexOf('png') < 0 &&
          file.type.indexOf('jpeg') < 0 &&
          file.type.indexOf('pdf') < 0
        ) {
          this.$message.error('文件格式错误')
          return false
        }
      },
      // 附件上传成功
      handleAttachmentSuccess(res, file, fileList) {
        this.attachmentFileList = fileList
      },
      // 附件删除
      handleAttachmentRemove(file, fileList) {
        this.attachmentFileList = fileList
        this.form.attachment = ''
      },
      // 提交按钮
      handleSubmit() {
        // 设置 banner 和 attachment，如果有url就取url
        if (this.bannerFileList.length) {
          if (this.bannerFileList[0].response) this.form.banner = this.bannerFileList[0].response.data.url
          else this.form.banner = this.bannerFileList[0].url
        }
        if (this.attachmentFileList.length) {
          if (this.attachmentFileList[0].response) {
            this.form.attachment = this.attachmentFileList[0].response.data.url
          } else this.form.attachment = this.attachmentFileList[0].url
        }
        // 验证表单
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.submitting = true
          const copyForm = JSON.parse(JSON.stringify(this.form))

          if (this.dialogTitle === '编辑') copyForm.id = this.curRowData.id
          // 提交
          saveNoticeAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getNoticeData()
          }).finally(() => this.submitting = false)
        })
      },
      // 获取通知数据
      getNoticeData() {
        this.tableLoading = true

        getNoticeDataAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.total = res.total
          this.pageSize = res.per_page
          this.tableLoading = false
        })
      }
    }
  }
</script>

<style scoped>
</style>