// 公告管理
import request from '@/utils/util.js'

// 新增、编辑
export function saveNoticeAPI(data) {
	return request.post('/announcement/save', data)
}

// 列表
export function getNoticeDataAPI(data) {
	return request.get('/announcement/list', data)
}
